import React from "react";
import './Notifications.css'
import Dashboard from "../../Admin/Dashboard/Dashboard";
import AddNotifications from "../Modals/AddNotifications/AddNotifications";

export default function Notification() {
  return (
    <>
      <Dashboard />
      <div className="main-content nurse-notify">
        <div className="container-fluid p-0 m-0">
          <div className="home">
            <div className="row mt-4">
              <div className="col-md-12">
                <div className="resident">
                  <div className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-3">
                    <h5>Notifications</h5>
                    <div className="btn-resident">
                        <AddNotifications/>
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table className="table-stripedd table table-bordered table-hover align-middle">
                      <thead className="table">
                        <tr>
                          <th scope="col">
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              <span
                                style={{
                                  color: "#fff",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                }}
                              >
                                ID
                              </span>{" "}
                              <img
                                className="updown-arrow"
                                src="/images/arrow-down-up.svg"
                                alt=""
                              />
                            </div>
                          </th>
                          <th scope="col">
                            Name
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">
                            Status
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">
                            Condition
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ashok Kumar</td>
                          <td className="text-danger">Critical</td>
                          <td>High Bp</td>
                          <td className="table-fix">
                            <button className="btn btn-sm ">
                              <img src="/images/eye-grey.svg" alt="" />
                            </button>
                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-grey.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/trash-grey.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>John doe</td>
                          <td className="text-danger">Critical</td>
                          <td>High Cholesterol</td>
                          <td className="table-fix">
                            <button className="btn btn-sm ">
                              <img src="/images/eye-grey.svg" alt="" />
                            </button>
                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-grey.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/trash-grey.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>John doe</td>
                          <td className="text-danger">Critical</td>
                          <td>High Cholesterol</td>
                          <td className="table-fix">
                            <button className="btn btn-sm ">
                              <img src="/images/eye-grey.svg" alt="" />
                            </button>
                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-grey.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/trash-grey.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>John doe</td>
                          <td className="text-danger">Critical</td>
                          <td>High Cholesterol</td>
                          <td className="table-fix">
                            <button className="btn btn-sm ">
                              <img src="/images/eye-grey.svg" alt="" />
                            </button>
                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-grey.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/trash-grey.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>John doe</td>
                          <td className="text-danger">Critical</td>
                          <td>High Cholesterol</td>
                          <td className="table-fix">
                            <button className="btn btn-sm ">
                              <img src="/images/eye-grey.svg" alt="" />
                            </button>
                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-grey.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/trash-grey.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>John doe</td>
                          <td className="text-danger">Critical</td>
                          <td>High Cholesterol</td>
                          <td className="table-fix">
                            <button className="btn btn-sm ">
                              <img src="/images/eye-grey.svg" alt="" />
                            </button>
                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-grey.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/trash-grey.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>John doe</td>
                          <td className="text-danger">Critical</td>
                          <td>High Cholesterol</td>
                          <td className="table-fix">
                            <button className="btn btn-sm ">
                              <img src="/images/eye-grey.svg" alt="" />
                            </button>
                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-grey.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/trash-grey.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ashok Kumar</td>
                          <td className="text-danger">Critical</td>
                          <td>High Bp</td>
                          <td className="table-fix">
                            <button className="btn btn-sm ">
                              <img src="/images/eye-grey.svg" alt="" />
                            </button>
                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/edit-grey.svg" alt="" />
                            </button>
                            <ul
                              className="edit-rt dropdown-menu text-center"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="borderbd mx-2">
                                <a class="dropdown-item" href="#">
                                  Recovered
                                </a>
                              </li>
                              <li className="mx-2">
                                <a className="dropdown-item" href="#">
                                  In Treatment
                                </a>
                              </li>
                            </ul>
                            <button className="btn btn-sm">
                              <img src="/images/trash-grey.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        {/* <!-- Add more rows as needed --> */}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="staff-pagination my-3">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination">
                      <li class="page-item">
                        <a class="page-link" href="#" aria-label="Previous">
                          <img src="/images/arrow-left-p.svg" />
                        </a>
                      </li>
                      <li class="page-item active" aria-current="page">
                        <a class="page-link" href="#">
                          1
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          2
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          3
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          4
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          5
                        </a>
                      </li>
                      <li class="page-item">
                        <a
                          class="page-link p-icon-next"
                          href="#"
                          aria-label="Next"
                        >
                          <img src="/images/arrow-left-p.svg" />
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
