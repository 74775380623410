import React from "react";
import Dashboard from "../Dashboard/Dashboard";
import "./Doctor.css";
import { useNavigate } from "react-router-dom";
import MyCalendar from "../../Calendar/Calendar";
import PateintDetails from "../Modals/PateintDetails/PateintDetails";
import PlusButton from "../../PlusButton/PlusButton";
import IncidentAlert from "../Modals/IncidentAlert/IncidentAlert";
import MedicalReport from "../Modals/MedicalReport/MedicalReport";

export default function Doctor() {
  const navigate = useNavigate();
  return (
    <>
      <Dashboard />
      <div className="main-content doctor-box">
        <div className="container-fluid p-0 m-0">
          <div className="home">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-8">
                  <div className="welcome">
                    <div className="container">
                      <div className="wellcome-txt">
                        <h6>Good Evening, Dr.Radhik</h6>
                        <p>Ready to tackle today's challenges?</p>
                      </div>
                      <div className="welcome-image lady-doctor">
                        <img src="/images/doctor.png" alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 d-none">
                    <div className="resident mt-3">
                      <div className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-3">
                        <h5>Resident Health Data Overview</h5>
                        <div className="left-content">
                          <div className="input-field position-relative">
                            <input
                              type="text"
                              className="form-control d-inline-block"
                              placeholder="Search Resident"
                            />
                            <span>
                              <img src="/images/search-icon.png" />
                            </span>
                          </div>
                          <div className="filtericon">
                            <button>
                              <img src="/images/filter-icon.svg" alt="" />
                            </button>
                          </div>
                          <div className="btn-resident">
                            <button
                              onClick={() => navigate("/Registration")}
                              className="btn btn-primary"
                            >
                              <i className="fa-light fa-plus"></i>
                              <span className="d-block">Add</span>
                              <span className="d-block">Resident</span>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="table-responsive">
                        <table className="table-stripedd table table-bordered table-hover align-middle">
                          <thead className="table">
                            <tr>
                              <th scope="col">
                                <div className="checkbox checbox-trans">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                  />
                                  <span
                                    style={{
                                      color: "#fff",
                                      fontWeight: "500",
                                      fontSize: "14px",
                                    }}
                                  >
                                    ID
                                  </span>{" "}
                                  <img
                                    className="updown-arrow"
                                    src="/images/arrow-down-up.svg"
                                    alt=""
                                  />
                                </div>
                              </th>
                              <th scope="col">
                                Name{" "}
                                <img
                                  className="updown-arrow"
                                  src="/images/arrow-down-up.svg"
                                  alt=""
                                />
                              </th>
                              <th scope="col">
                                Status{" "}
                                <img
                                  className="updown-arrow"
                                  src="/images/arrow-down-up.svg"
                                  alt=""
                                />
                              </th>
                              <th scope="col">
                                Caretaker{" "}
                                <img
                                  className="updown-arrow"
                                  src="/images/arrow-down-up.svg"
                                  alt=""
                                />
                              </th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="checkbox checbox-trans">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                  />
                                  P-002
                                </div>
                              </td>
                              <td>Ashok Kumar</td>
                              <td className="text-success">Recovered</td>
                              <td>Rajesh</td>
                              <td className="table-fix">
                                <PateintDetails />

                                <button
                                  className="btn btn-sm"
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <img src="/images/edit-2.svg" alt="" />
                                </button>
                                <ul
                                  className="edit-rt dropdown-menu text-center"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  <li className="borderbd mx-2">
                                    <a class="dropdown-item" href="#">
                                      Recovered
                                    </a>
                                  </li>
                                  <li className="mx-2">
                                    <a className="dropdown-item" href="#">
                                      In Treatment
                                    </a>
                                  </li>
                                </ul>
                                <button className="btn btn-sm">
                                  <img src="/images/trash.svg" alt="" />
                                </button>
                              </td>
                            </tr>
                            <tr style={{ backgroundcolor: "#F6F6F6" }}>
                              <td>
                                <div className="checkbox checbox-trans">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                  />
                                  P-002
                                </div>
                              </td>
                              <td>Ashok Kumar</td>
                              <td className="text-warning">Treatment</td>
                              <td>Rajesh</td>
                              <td className="table-fix">
                                <PateintDetails />

                                <button className="btn btn-sm" type="button">
                                  <img src="/images/edit-2.svg" alt="" />
                                </button>

                                <button className="btn btn-sm">
                                  <img src="/images/trash.svg" alt="" />
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="checkbox checbox-trans">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                  />
                                  P-002
                                </div>
                              </td>
                              <td>Ashok Kumar</td>
                              <td className="text-warning">Treatment</td>
                              <td>Rajesh</td>
                              <td className="table-fix">
                                <PateintDetails />
                                <button className="btn btn-sm">
                                  <img src="/images/edit-2.svg" alt="" />
                                </button>
                                <button className="btn btn-sm">
                                  <img src="/images/trash.svg" alt="" />
                                </button>
                              </td>
                            </tr>
                            <tr style={{ backgroundcolor: "#F6F6F6" }}>
                              <td>
                                <div className="checkbox checbox-trans">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                  />
                                  P-002
                                </div>
                              </td>
                              <td>Ashok Kumar</td>
                              <td className="text-warning">Treatment</td>
                              <td>Rajesh</td>
                              <td className="table-fix">
                                <PateintDetails />
                                <button className="btn btn-sm">
                                  <img src="/images/edit-2.svg" alt="" />
                                </button>
                                <button className="btn btn-sm">
                                  <img src="/images/trash.svg" alt="" />
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="checkbox checbox-trans">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                  />
                                  P-002
                                </div>
                              </td>
                              <td>Ashok Kumar</td>
                              <td className="text-warning">Treatment</td>
                              <td>Rajesh</td>
                              <td className="table-fix">
                                <PateintDetails />
                                <button className="btn btn-sm">
                                  <img src="/images/edit-2.svg" alt="" />
                                </button>
                                <button className="btn btn-sm">
                                  <img src="/images/trash.svg" alt="" />
                                </button>
                              </td>
                            </tr>
                            <tr style={{ backgroundcolor: "#F6F6F6" }}>
                              <td>
                                <div className="checkbox checbox-trans">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                  />
                                  P-002
                                </div>
                              </td>
                              <td>Ashok Kumar</td>
                              <td className="text-warning">Treatment</td>
                              <td>Rajesh</td>
                              <td className="table-fix">
                                <PateintDetails />
                                <button className="btn btn-sm">
                                  <img src="/images/edit-2.svg" alt="" />
                                </button>
                                <button className="btn btn-sm">
                                  <img src="/images/trash.svg" alt="" />
                                </button>
                              </td>
                            </tr>

                            {/* <!-- Add more rows as needed --> */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="alerts-container">
                          <div className="alerts-header">
                            <h3>Critical Alerts</h3>
                            <div className="d-flex align-items-center gap-2">
                            <a href="#" className="view-all">
                              View all
                            </a>
                            <IncidentAlert/>
                            </div>
                          </div>
                          <div>
                          <div className="alert-item">
                            <img
                              src="/images/Critical-Alerts-profile.svg"
                              alt="Profile Picture"
                              className="profile-pic"
                            />
                            <div className="alert-info">
                              <h4>Ashok Kumar</h4>
                              <p>Not able to walk properly since morning</p>
                            </div>
                          </div>

                          <div className="alert-item">
                            <img
                              src="/images/Critical-Alerts-profile.svg"
                              alt="Profile Picture"
                              className="profile-pic"
                            />
                            <div className="alert-info">
                              <h4>Marie</h4>
                              <p>Not able to walk properly since morning</p>
                            </div>
                          </div>
                          </div>
                          <div className="attention-required">
                            <span className="attention-icon">
                              <i className="fa-solid fa-hexagon-exclamation"></i>
                            </span>
                            <span>Attention Required</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="to-do-list-box">
                            <div className="to-do-header d-block d-sm-flex align-items-center justify-content-between">
                                <div className="d-grid gap-1 mb-2 mb-sm-0">
                                    <h3>To do list</h3>
                                    <p>Your todays appointments</p>
                                </div>
                                <div className="mb-3 mb-sm-0">
                                   <PlusButton plusBtnName="Add a To-Do"/>
                                </div>
                            </div>
                            <div className="to-do-meeting-card">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="mobility" id="fully-independent" value="fully-independent" checked/>
                                    <label className="form-check-label text-decoration-line-through" for="fully-independent">
                                        Meeting with Dr.sharma
                                    </label>
                                </div>
                                <p className="text-decoration-line-through">11:30 am</p>
                            </div>
                            <div className="to-do-meeting-card">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="mobility" id="fully-independent" value="fully-independent"/>
                                    <label className="form-check-label" for="fully-independent">
                                        Meeting with Dr.sharma
                                    </label>
                                </div>
                                <p className="">11:30 am</p>
                            </div>
                            <div className="to-do-meeting-card">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="mobility" id="fully-independent" value="fully-independent"/>
                                    <label className="form-check-label" for="fully-independent">
                                        Meeting with Dr.sharma
                                    </label>
                                </div>
                                <p className="">11:30 am</p>
                            </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="messages-container">
                          <div className="header">
                            <h2>Messages</h2>
                            <a href="#" className="new-message">
                              View All
                            </a>
                          </div>
                          <div className="search-bar">
                            <input type="text" placeholder="Search" />
                            <i className="fa-solid fa-magnifying-glass"></i>
                          </div>
                          <div className="message-list">
                            <div className="message-item">
                              <img
                                src="/images/Critical-Alerts-profile.svg"
                                alt="Profile Picture"
                              />
                              <div className="message-content">
                                <div className="message-header">
                                  <span className="name">Rajesh</span>
                                  <span className="time">11:30 am</span>
                                </div>
                                <p>
                                  Medicines are changed as per prescriptions
                                </p>
                              </div>
                            </div>
                            <div className="message-item">
                              <img
                                src="/images/profile-image2.svg"
                                alt="Profile Picture"
                              />
                              <div className="message-content">
                                <div className="message-header">
                                  <span className="name">Mira</span>
                                  <span className="time">11:30 am</span>
                                </div>
                                <p>
                                  Medicines are changed as per prescriptions
                                </p>
                              </div>
                            </div>
                            <div className="message-item">
                              <img
                                src="/images/Critical-Alerts-profile.svg"
                                alt="Profile Picture"
                              />
                              <div className="message-content">
                                <div className="message-header">
                                  <span className="name">John Doe</span>
                                  <span className="time">11:30 am</span>
                                </div>
                                <p>
                                  Medicines are changed as per prescriptions
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="d-grid justify-content-center mt-5">
                            <PlusButton plusBtnName="New Message"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div>
                    <MyCalendar />
                  </div>
                  <div className="medical-reports">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h5>Medical Reports</h5>
                      <div className="d-flex align-items-center gap-2">
                      <a href="#" className="btn-link">
                        View all
                      </a>
                      <MedicalReport/>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-stripedd">
                        <thead className="table-dark">
                          <tr>
                            <th scope="col">
                              Name{" "}
                              <span>
                                <img
                                  className="updown-arrow"
                                  src="/images/arrow-down-up.svg"
                                  alt=""
                                />
                              </span>
                            </th>
                            <th scope="col">
                              Count{" "}
                              <span>
                                <img
                                  className="updown-arrow"
                                  src="/images/arrow-down-up.svg"
                                  alt=""
                                />
                              </span>
                            </th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Blood Test</td>
                            <td>5</td>
                            <td>
                              <button className="btn btn-sm ">
                                <img src="/images/eye.svg" alt="" />
                              </button>
                            </td>
                          </tr>
                          <tr style={{ backgroundcolor: "#F6F6F6" }}>
                            <td>X-Ray</td>
                            <td>10</td>
                            <td>
                              <button className="btn btn-sm ">
                                <img src="/images/eye.svg" alt="" />
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>Urine Test</td>
                            <td>6</td>
                            <td>
                              <button className="btn btn-sm ">
                                <img src="/images/eye.svg" alt="" />
                              </button>
                            </td>
                          </tr>
                          <tr style={{ backgroundcolor: "#F6F6F6;" }}>
                            <td>Thyroid Test</td>
                            <td>12</td>
                            <td>
                              <button className="btn btn-sm ">
                                <img src="/images/eye.svg" alt="" />
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>X-Ray</td>
                            <td>10</td>
                            <td>
                              <button className="btn btn-sm ">
                                <img src="/images/eye.svg" alt="" />
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="messages-container mt-3 d-none">
                    <div className="header header-tow">
                      <div>
                        <h2>Today’s Appointments</h2>
                        <p>Your todays appointments</p>
                      </div>
                      <a href="#" className="new-message">
                        {" "}
                        View All
                      </a>
                    </div>
                    <div className="message-list">
                      <div className="message-item">
                        <img
                          src="/images/Critical-Alerts-profile.svg"
                          alt="Profile Picture"
                        />
                        <div className="message-content">
                          <div className="message-header">
                            <span className="name">Rajesh</span>
                            <span className="time">
                              11:30 am
                              <button>
                                <i className="fa-solid fa-ellipsis-stroke-vertical"></i>
                              </button>
                            </span>
                          </div>
                          <p>New Patient</p>
                        </div>
                      </div>
                      <div className="message-item">
                        <img
                          src="/images/profile-image2.svg"
                          alt="Profile Picture"
                        />
                        <div className="message-content">
                          <div className="message-header">
                            <span className="name">Mira</span>
                            <span className="time">
                              11:30 am
                              <button>
                                <i className="fa-solid fa-ellipsis-stroke-vertical"></i>
                              </button>
                            </span>
                          </div>
                          <p>Weekly visit</p>
                        </div>
                      </div>
                      <div className="message-item">
                        <img
                          src="/images/Critical-Alerts-profile.svg"
                          alt="Profile Picture"
                        />
                        <div className="message-content">
                          <div className="message-header">
                            <span className="name">John Doe</span>
                            <span className="time">
                              11:30 am
                              <button>
                                <i className="fa-solid fa-ellipsis-stroke-vertical"></i>
                              </button>
                            </span>
                          </div>
                          <p>Consultation</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
