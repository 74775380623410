import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./AddNotifications.css";

export default function AddNotifications() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button className="btn btn-primary" onClick={handleShow}>
        <i className="fa-light fa-plus"></i>
        <span className="d-block">Add</span>
        <span className="d-block">Notification</span>
      </Button>

      <Modal show={show} onHide={handleClose} className="add-notify-popup">
        <Modal.Header closeButton>
          <Modal.Title>Add Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add-bill-box">
            <form>
              <div className="row gx-3">
                <div className="col-sm-6">
                  <div className="add-bill-fields">
                    <div class="mb-3">
                      <label for="ID" class="form-label">
                        ID
                      </label>
                      <input
                        class="form-control"
                        type="text"
                        id="ID"
                        placeholder="1234567"
                        aria-label="input example"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="add-bill-fields">
                    <div class="mb-3">
                      <label for="Name" class="form-label">
                        Resident Name
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="Name"
                        placeholder="Value"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="add-bill-fields">
                    <div class="mb-3">
                      <label for="Designation" class="form-label">
                        Health Status
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="Designation"
                        placeholder="Value"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="add-bill-fields">
                    <div class="mb-3">
                      <label for="Designation" class="form-label">
                        Condition
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="Designation"
                        placeholder="Value"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="add-bill-fields">
                    <div class="mb-3">
                      <label class="form-label">Alert Send to :</label>
                      <div className="d-flex gap-3">
                        <div className="checkbox checbox-trans">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="Family"
                          />
                          <label for="Family" class="form-label mb-0">
                            Family
                          </label>
                        </div>
                        <div className="checkbox checbox-trans">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="Doctor"
                          />
                          <label for="Doctor" class="form-label mb-0">
                            Doctor
                          </label>
                        </div>
                        <div className="checkbox checbox-trans">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="Caregiver"
                          />
                          <label for="Caregiver" class="form-label mb-0">
                            Caregiver
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="add-bill-btn mt-3">
                  <div>
                    <button className="save-btn" type="submit">
                      Submit
                    </button>
                  </div>
                  <div>
                    <button className="cancel-btn" type="submit">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
