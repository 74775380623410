import React from 'react'
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import './MedicalReport.css'

export default function MedicalReport() {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button className="btn plus-btn-box-popup" onClick={handleShow}>
        <i className="fa-light fa-plus"></i>
      </Button>

      <Modal show={show} onHide={handleClose} className="medical-report-popup">
        <Modal.Header closeButton>
          <Modal.Title>Medical Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add-bill-box">
            <form>
              <div className="row gx-3">
                <div className="col-sm-6">
                  <div className="add-bill-fields">
                    <div class="mb-3">
                      <label for="MedicalTest" class="form-label">
                      Medical Test Name *
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="MedicalTest"
                        placeholder="Value"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="add-bill-fields">
                    <div class="mb-3">
                      <label for="IncidentDate" class="form-label">
                        Test Date*
                      </label>
                      <input
                        type="date"
                        class="form-control datepicker"
                        id="IncidentDate"
                        placeholder="Value"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="add-bill-fields">
                    <div class="mb-3">
                      <h3 className='fs-6 mb-3'>
                      Upload File
                      </h3>
                        <label for="file-upload" className="custom-file-upload">
                            <span><img src='/images/upload-doc-icon.svg' alt='Upload-doc'/></span>
                           <span className='inline-block drag-text'>Drag & Drop file here or <span className='inline'>Choose file</span></span>
                        </label>
                        <input id="file-upload" type="file" />
                    </div>
                  </div>
                </div>
                <div className="add-bill-btn">
                  <div>
                    <button className="save-btn" type="submit">
                      Save
                    </button>
                  </div>
                  <div>
                    <button className="cancel-btn" type="submit">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
