import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import './OrderMedication.css'

export default function OrderMedications() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button className="btn btn-primary" onClick={handleShow}>
        <i className="fa-light fa-plus"></i>
        <span className="d-block">Order</span>
        <span className="d-block">Medication</span>
      </Button>

      <Modal show={show} onHide={handleClose} className="add-medications-popup">
        <Modal.Header closeButton>
          <Modal.Title className="w-100 me-4">
            <div className="d-flex justify-content-between">
            <div>Order Medication</div>
            <div className="cancel-order">
                <p>Cancel Order</p>
            </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add-bill-box">
            <form>
              <div className="row gx-3">
                <div className="col-sm-6">
                  <div className="add-bill-fields">
                    <div class="mb-3">
                      <label for="ID" class="form-label">
                        ID
                      </label>
                      <input
                        class="form-control"
                        type="text"
                        id="ID"
                        placeholder="1234567"
                        aria-label="input example"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="add-bill-fields">
                    <div class="mb-3">
                      <label for="Name" class="form-label">
                      Medicine Name
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="Name"
                        placeholder="Value"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="add-bill-fields">
                    <div class="mb-3">
                      <label for="Quantity" class="form-label">
                      Quantity
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="Quantity"
                        placeholder="Value"
                      />
                    </div>
                  </div>
                </div>
                <div className="add-bill-btn mt-3">
                  <div>
                    <button className="save-btn" type="submit">
                      Submit
                    </button>
                  </div>
                  <div>
                    <button className="cancel-btn" type="submit">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
