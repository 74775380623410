import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./IncidentAlert.css";

export default function IncidentAlert() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button className="btn plus-btn-box-popup" onClick={handleShow}>
        <i className="fa-light fa-plus"></i>
      </Button>

      <Modal show={show} onHide={handleClose} className="incident-alert-popup">
        <Modal.Header closeButton>
          <Modal.Title>Incident Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add-bill-box">
            <form>
              <div className="row gx-3">
                <div className="col-sm-6">
                  <div className="add-bill-fields">
                    <div class="mb-3">
                      <label for="IncidentName" class="form-label">
                        Incident Name*
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="IncidentName"
                        placeholder="Value"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="add-bill-fields">
                    <div class="mb-3">
                      <label for="IncidentDate" class="form-label">
                        Incident Date*
                      </label>
                      <input
                        type="date"
                        class="form-control datepicker"
                        id="IncidentDate"
                        placeholder="Value"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <label className="form-label">Incident Type*</label>
                  <div className="d-flex align-items-center gap-3">
                    <div class="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Normal"
                      />
                      <label className="form-label" for="Normal">
                        Normal
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Average"
                      />
                      <label className="form-label" for="Average">
                        Average
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Critical"
                      />
                      <label className="form-label" for="Critical">
                        Critical
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="add-bill-fields">
                    <div class="mb-3">
                      <label for="Desription" class="form-label">
                        Desription
                      </label>
                      <textarea
                        type="date"
                        class="form-control"
                        id="Desription"
                        placeholder="Value" rows="3"
                      />
                    </div>
                  </div>
                </div>
                <div className="add-bill-btn">
                  <div>
                    <button className="save-btn" type="submit">
                      Save
                    </button>
                  </div>
                  <div>
                    <button className="cancel-btn" type="submit">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
